<template>
  <div class="h-min-content w-full">
    <Wrapper class="max-w-2xl">
      <div class="mx-auto max-w-2xl">
        <FormDepositProduct
          v-if="tariffFormat === '1'"
          title="Tarifario tipo deposito"
          description="Ingrese la información del tarifario"
          :details-view="true"
        />
        <FormCreditCardProduct
          v-if="tariffFormat === '2'"
          title="Tarifario tipo tarjeta de crédito"
          description="Ingrese la información del tarifario"
          :details-view="true"
        />
        <FormCreditProduct
          v-if="tariffFormat === '3'"
          title="Tarifario tipo credito"
          description="Ingrese la información del tarifario"
          :details-view="true"
        />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormDepositProduct from '@/components/Dashboard/Tariffs/FormTariff/FormDepositProduct.vue'
import FormCreditCardProduct from '@/components/Dashboard/Tariffs/FormTariff/FormCreditCardProduct.vue'
import FormCreditProduct from '@/components/Dashboard/Tariffs/FormTariff/FormCreditProduct.vue'
export default {
  components: {
    FormDepositProduct,
    FormCreditCardProduct,
    FormCreditProduct,
  },
  data() {
    return {
      tariffFormat: '',
    }
  },
  computed: {
    ...mapState({
      formOptions: 'form',
    }),
  },
  mounted() {
    const { format } = this.$route.query
    if (format) this.tariffFormat = format
  },
}
</script>

<style></style>
